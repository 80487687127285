export const languageBuild = (data, path, language) => {
  const supportLanguage = ["mn", "eng", "cn"];

  if (data && data[language] && data[language][path]) {
    return data[language][path];
  }

  for (const lang of supportLanguage) {
    if (data && data[lang] && data[lang][path]) {
      return <p className="red-color">{data[lang][path]}</p>;
    }
  }

  return null;
};
