import axios from "../../axios-base";

const errorBuild = (error) => {
  let resError = "Алдаа гарлаа дахин оролдож үзнэ үү";

  if (resError) {
    resError = error.message;
  }

  if (error.response !== undefined && error.response.status !== undefined) {
    resError = error.response.status;
  }
  if (
    error.response !== undefined &&
    error.response.data !== undefined &&
    error.response.data.error !== undefined
  ) {
    resError = error.response.data.error.message;
  }
  return resError;
};

export const clear = () => {
  return {
    type: "CLEAR_SERVICE",
  };
};

// SAVE SERVICE
export const saveSeriviesInit = () => {
  return {
    type: "CREATE_SERVICE_INIT",
  };
};

export const saveSerivies = (data) => {
  return function (dispatch, getState) {
    dispatch(saveSeriviesStart());
    axios
      .post(`/servicies`, data)
      .then((response) => {
        const result = response.data;
        dispatch(saveSeriviesSuccess(result));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(saveSeriviesError(resError));
      });
  };
};

export const saveSeriviesStart = () => {
  return {
    type: "CREATE_SERVICE_START",
  };
};

export const saveSeriviesSuccess = (result) => {
  return {
    type: "CREATE_SERVICE_SUCCESS",
    serivie: result,
  };
};

export const saveSeriviesError = (error) => {
  return {
    type: "CREATE_SERVICE_ERROR",
    error,
  };
};

// Excel serivie
export const getExcelData = (query) => {
  return function (dispatch) {
    dispatch(getExcelDataStart());
    axios
      .get("servicies/excel?" + query)
      .then((response) => {
        const data = response.data.data;
        dispatch(getExcelDataSuccess(data));
      })
      .catch((error) => {
        let resError = errorBuild(error);
        dispatch(getExcelDataError(resError));
      });
  };
};

const getExcelDataStart = () => {
  return {
    type: "GET_SERVICE_EXCELDATA_START",
  };
};

const getExcelDataSuccess = (data) => {
  return {
    type: "GET_SERVICE_EXCELDATA_SUCCESS",
    excel: data,
  };
};

const getExcelDataError = (error) => {
  return {
    type: "GET_SERVICE_EXCELDATA_ERROR",
    error,
  };
};

// LOAD SERVICE

export const loadSerivies = (query = "") => {
  return function (dispatch, getState) {
    dispatch(loadSeriviesStart());
    axios
      .get("servicies?" + query)
      .then((response) => {
        const loadSerivies = response.data.data;
        const pagination = response.data.pagination;
        dispatch(loadSeriviesSuccess(loadSerivies));
        dispatch(loadPagination(pagination));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(loadSeriviesError(resError));
      });
  };
};

export const loadSeriviesStart = () => {
  return {
    type: "LOAD_SERVICE_START",
  };
};

export const loadSeriviesSuccess = (servicies, pagination) => {
  return {
    type: "LOAD_SERVICE_SUCCESS",
    servicies,
    pagination,
  };
};

export const loadSeriviesError = (error) => {
  return {
    type: "LOAD_SERVICE_ERROR",
    error,
  };
};

export const loadPagination = (pagination) => {
  return {
    type: "LOAD_PAGINATION",
    pagination,
  };
};

export const deleteMultSerivies = (ids) => {
  return function (dispatch, getState) {
    dispatch(deleteMultStart());
    axios
      .delete("servicies/delete", { params: { id: ids } })
      .then((response) => {
        const deleteSerivies = response.data.data;
        dispatch(deleteSeriviesSuccess(deleteSerivies));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(deleteSeriviesError(resError));
      });
  };
};

export const deleteMultStart = () => {
  return {
    type: "DELETE_MULT_SERVICE_START",
  };
};

export const deleteSeriviesSuccess = (deleteData) => {
  return {
    type: "DELETE_MULT_SERVICE_SUCCESS",
    deleteSerivies: deleteData,
  };
};

export const deleteSeriviesError = (error) => {
  return {
    type: "DELETE_MULT_SERVICE_ERROR",
    error,
  };
};

// GET SERVICE

export const getInit = () => {
  return {
    type: "GET_SERVICE_INIT",
  };
};

export const getSerivies = (id) => {
  return function (dispatch, getState) {
    dispatch(getSeriviesStart());
    axios
      .get("servicies/" + id)
      .then((response) => {
        const serivie = response.data.data;
        dispatch(getSeriviesSuccess(serivie));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(getSeriviesError(resError));
      });
  };
};

export const getSeriviesStart = () => {
  return {
    type: "GET_SERVICE_START",
  };
};

export const getSeriviesSuccess = (service) => {
  return {
    type: "GET_SERVICE_SUCCESS",
    service,
  };
};

export const getSeriviesError = (error) => {
  return {
    type: "GET_SERVICE_ERROR",
    error,
  };
};

//UPDATE SERVICE

export const updateSerivies = (id, data) => {
  return function (dispatch) {
    dispatch(updateSeriviesStart());
    axios
      .put(`servicies/${id}`, data)
      .then((response) => {
        const result = response.data;
        dispatch(updateSeriviesSuccess(result));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(updateSeriviesError(resError));
      });
  };
};

export const updateSeriviesStart = () => {
  return {
    type: "UPDATE_SERVICE_START",
  };
};

export const updateSeriviesSuccess = (result) => {
  return {
    type: "UPDATE_SERVICE_SUCCESS",
    updateSerivies: result,
  };
};

export const updateSeriviesError = (error) => {
  return {
    type: "UPDATE_SERVICE_ERROR",
    error,
  };
};

export const getCountSerivies = () => {
  return function (dispatch) {
    dispatch(getCountSeriviesStart());

    axios
      .get(`servicies/count`)
      .then((response) => {
        const result = response.data.data;
        dispatch(getCountSeriviesSuccess(result));
      })
      .catch((error) => {
        const resError = errorBuild(error);
        dispatch(getCountSeriviesError(resError));
      });
  };
};

export const getCountSeriviesStart = () => {
  return {
    type: "GET_COUNT_SERVICE_START",
  };
};

export const getCountSeriviesSuccess = (result) => {
  return {
    type: "GET_COUNT_SERVICE_SUCCESS",
    orderCount: result,
  };
};

export const getCountSeriviesError = (error) => {
  return {
    type: "GET_COUNT_SERVICE_ERROR",
    error,
  };
};
