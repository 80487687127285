const base = {
  // siteUrl: "http://localhost:3000/",
  // baseUrl: "http://localhost:3000/",
  // cdnUrl: "http://localhost:1020/uploads/",
  // apiUrl: "http://localhost:1020/v1/api/",
  cdnUrl: "https://ganashidkhuleg.com/uploads/",
  baseUrl: "https://admin.ganashidkhuleg.com/",
  siteUrl: "https://ganashidkhuleg.com/",
  apiUrl: "https://admin.ganashidkhuleg.com/api/",
};

export default base;
